// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-legal-docs-template-tsx": () => import("../src/templates/legalDocsTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-docs-template-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-countdown-tsx": () => import("../src/pages/countdown.tsx" /* webpackChunkName: "component---src-pages-countdown-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mini-hackathon-tsx": () => import("../src/pages/mini-hackathon.tsx" /* webpackChunkName: "component---src-pages-mini-hackathon-tsx" */)
}

